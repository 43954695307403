/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js' // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
;(window as any).global = window
global.Buffer = global.Buffer || require('buffer').Buffer

declare const window: any
declare const global: any
if (!window.process) {
  window.process = {}
}
window.process = window.process || {}
window.process.env = window.process.env || {}
global.Buffer = global.Buffer || require('buffer').Buffer
window.process.version = ''
window.process.browser = true
